<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Soubory - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Formát">
          <p>{{ this.detail.fie_format }}</p>
        </a-form-item>

        <a-form-item
          label="Název"
          :validate-status="error('fie_name') ? 'error' : ''"
          :help="error('fie_name') || ''"
        >
          <a-input
            v-decorator="[`fie_name`, {rules: [{max: 90, message: 'Název nemůže být delší než 90 znaků!'}, {required: true, message: 'Název musí být vyplněna!'}]}]"
            placeholder="Název"
          />
        </a-form-item>

        <a-form-item
          label="Kategorie"
          :validate-status="error('fcy_id') ? 'error' : ''"
          :help="error('fcy_id') || ''"
        >
          <a-select
            v-decorator="[`fcy_id`, {rules: [{required: true, message: 'Kategorie musí být vyplněna!'}]}]"
            showSearch
            placeholder="-- Vyber kategorii --"
            :options="categories.map(x => { return { value: x.fcy_id, label: x.fcy_name }})"
            optionFilterProp="children"
            :filterOption="filterOption"
            :loading="!loaded.includes('fcy')"
          />
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../services/forms'

export default {
  components: { ActionTools },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/file',
          title: 'Seznam',
        },
      ],
      form: this.$form.createForm(this),
      id: this.$route.params.id,
      loaded: [],
    }
  },
  computed: {
    detail: function () {
      return this.$store.getters['file/getDetail']
    },
    categories: function () {
      return this.$store.getters['fileCategory/filteredItems']('')
    },
  },
  methods: {
    getData() {
      return {
        fcy_id: this.form.getFieldValue('fcy_id'),
        fie_name: this.form.getFieldValue('fie_name'),
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('file/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    initData() {
      Promise.all([
        this.$store.dispatch('fileCategory/getList'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {})
        .finally(() => {
          this.loaded.push('fcy')
        })
    },
    initDetail() {
      this.$store.dispatch('file/getOne', this.id)
        .then(() => {
          this.form.setFieldsValue({
            fcy_id: this.detail.fcy_id,
            fie_name: this.detail.fie_name,
          })
        })
        .catch(() => {})
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.loaded = []
    },
  },
  created() {
    this.initData()
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      this.initDetail()
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
